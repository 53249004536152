import { defineComponent, ref, watch } from 'vue';
import { ElSelect, ElOption } from 'element-plus';
import SvgIcon from '@/components/atoms/SvgIcon/SvgIcon.vue';

export default defineComponent({
  name: 'SelectField',

  components: {
    SvgIcon,
    ElSelect,
    ElOption,
  },

  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'Select',
    },
    required: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => [],
    },
    modelValue: {
      type: [String, Array, Boolean],
      default: '',
    },
    valueKey: {
      type: String,
      default: 'value',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    filterable: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    collapseTags: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['update:modelValue'],

  setup(props, { emit }) {
    const localValue = ref(props.modelValue);

    watch(
      () => localValue.value,
      (newVal) => {
        emit('update:modelValue', newVal);
      },
    );

    return {
      localValue,
    };
  },
});
