
import { computed, defineComponent, PropType } from 'vue';
import { ElMain } from 'element-plus';
import Container from '@/components/atoms/Layout/Container.vue';
import SetupDocs from '@/components/organisms/Docs/SetupDocs.vue';
import IdTables from '@/components/organisms/Docs/IdTables.vue';
import { ConfigKey } from '@/config/default';

export default defineComponent({
  props: {
    doctype: String as PropType<'events' | 'button'>,
  },
  components: { Container, ElMain, SetupDocs, IdTables },
  setup(props) {
    const isValidProp = computed(() => props.doctype === 'button' || props.doctype === 'events');

    const fileName = computed(() => (props.doctype === 'events' ? 'rbiEvents' : 'rbiEventsButton'));
    const header = computed(() => (props.doctype === 'events' ? 'RBI Events Skript' : 'RBI Button Skript'));
    const configOptions = computed<ConfigKey[]>(() =>
      props.doctype === 'events'
        ? ['itemsPerPage', 'userFilters', 'filterBy', 'searchResultsPresets']
        : ['itemsPerPage', 'searchResultsPresets'],
    );

    return { isValidProp, fileName, header, configOptions };
  },
});
