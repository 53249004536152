
import { defineComponent, PropType, reactive, toRefs } from 'vue';
import { ElTable, ElTableColumn, ElCollapse, ElCollapseItem } from 'element-plus';
import { useApi } from '@/api';
import { ConfigKey } from '@/config/default';
import { IDistrict } from '@/typings/district.types';
import { IOrganizer } from '@/typings/organizer.types';
import { ISimpleVenue } from '@/typings/venue.types';
import { ITargetGroup } from '@/typings/target-group.types';
import { IEventMetaInfo, IFringeEventDetails } from '@/typings/event.types';

const { api } = useApi();

export default defineComponent({
  components: { ElTable, ElTableColumn, ElCollapse, ElCollapseItem },
  props: {
    configOptions: {
      type: Array as PropType<ConfigKey[]>,
      required: true,
    },
  },

  setup(props) {
    const data = reactive({
      districts: [] as IDistrict[],
      venues: [] as ISimpleVenue[],
      organizers: [] as IOrganizer[],
      targetGroups: [] as ITargetGroup[],
      fringeEvents: [] as IFringeEventDetails[],
      categories: [] as IEventMetaInfo[],
    });

    api
      .GET_DISTRICTS()
      .then((result) => {
        data.districts = result.data;
      })
      .catch((err) => console.log('Could not fetch districts', err));

    api
      .GET_TARGET_GROUPS()
      .then((result) => {
        data.targetGroups = result.data;
      })
      .catch((err) => console.log('Could not fetch targetGroups', err));

    api
      .GET_FRINGE_EVENTS()
      .then((result) => {
        data.fringeEvents = result.data;
      })
      .catch((err) => console.log('Could not fetch fringeEvents', err));

    api
      .GET_EVENT_CATEGORIES()
      .then((result) => {
        data.categories = result.data;
      })
      .catch((err) => console.log('Could not fetch categories', err));

    if (props.configOptions.includes('filterBy')) {
      api
        .GET_VENUES()
        .then((result) => {
          data.venues = result.data;
        })
        .catch((err) => console.log('Could not fetch venues', err));

      api
        .GET_ORGANIZERS()
        .then((result) => {
          data.organizers = result.data;
        })
        .catch((err) => console.log('Could not fetch organizers', err));
    }

    return { ...toRefs(data) };
  },
});
