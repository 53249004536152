import { DateValue, uuid } from '@/typings/custom.types';
import { IOrganizer } from './organizer.types';

// eslint-disable-next-line no-shadow
export enum EViewMode {
  GRID = 'grid',
  LIST = 'list',
}

// eslint-disable-next-line no-shadow
export enum EIntervalTypes {
  NONE = 'NONE',
  NO_REPETITION = 'NO_REPETITION',
  WEEKLY = 'WEEKLY',
  EVERY_TWO_WEEKS = 'EVERY_TWO_WEEKS',
  EVERY_THREE_WEEKS = 'EVERY_THREE_WEEKS',
  EVERY_FOUR_WEEKS = 'EVERY_FOUR_WEEKS',
}

// eslint-disable-next-line no-shadow
enum EEventDateTypes {
  ONE_TIME = 'UNIQUE',
  MULTI_DAY = 'MULTIPLE',
  INDIVIDUAL = 'INDIVIDUAL',
}

// eslint-disable-next-line no-shadow
export enum EEventPriceType {
  FREE = 'FREE',
  CHARGEABLE = 'CHARGEABLE',
}

export interface IEventDateInfo {
  start: DateValue;
  end: DateValue;
  period: boolean;
}

export interface IEventDateTime {
  date: DateValue;
  start: string;
  end: string;
}

export interface IEventDistrictCity {
  city: string;
  district: string;
  districtCityId: uuid;
}

export interface IEventImage {
  id: uuid;
  title: string;
  description: string;
  copyright: string;
}

export interface IEventDateInfoExtend {
  date: IEventDateInfo;
  end: string;
  interval: EIntervalTypes;
  start: string;
  type: EEventDateTypes;
}

export interface IVenueCoordinate {
  latitude: number;
  longitude: number;
}

export interface IVenueAddress {
  street: string;
  houseNumber: string;
  city?: string;
  postalCode: string;
  districtCityId: uuid;
}

export interface IEventVenueExtend {
  name: string;
  room: string;
  coordinate: IVenueCoordinate;
  address: IVenueAddress;
}

export interface IEventPricingInfo {
  from: number;
  to: number;
  ticketSystemLink: string;
  description: string;
}

export interface IEventPricing {
  type: EEventPriceType;
  info: IEventPricingInfo;
}

export interface IEventResultItem {
  eventId: uuid;
  image: IEventImage;
  dateInfo: IEventDateInfoExtend;
  title: string;
  description: string;
  venue: IEventVenueExtend;
  price: IEventPricing;
  slug: string;
  districtCity: IEventDistrictCity;
}

export interface IEventMetaInfo {
  id: uuid;
  label: string;
  value?: uuid;
  children?: IEventMetaInfo[];
}

export interface IFringeEventDetails {
  id: uuid;
  slug: string;
  title: string;
  description: string;
  period: {
    start: Date;
    end: Date;
  };
  organizer: IOrganizer;
  eventIds: uuid[];
}
