<template>
  <div class="container">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
@import '../../../assets/styles/variables';

.container {
  max-width: $screen-xl;
  font-family: $mainFonts;
  color: $baseColor;
  margin: 0 auto;
}
</style>
