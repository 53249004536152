export const apiRoutes = {
  EVENT_SEARCH: '/event-management/events/internal/search',
  GET_IMAGE: '/media-management/images/{id}/{res}',
  GET_VENUES: '/venue-management/venues/status/approved',
  GET_EVENT_CATEGORIES: '/meta-management/event-categories',
  GET_DISTRICTS: '/meta-management/districts',
  GET_ORGANIZERS: '/account-management/accounts/event-organizers/approved',
  GET_TARGET_GROUPS: '/meta-management/target-groups',
  GET_FRINGE_EVENTS: '/event-management/frame-events',
};
