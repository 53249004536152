import { EImageResolution } from '@/api/request.types';
import { apiRoutes } from '@/api/routes';
import { BASE_PATH } from '@/api/config';

export function useMedia() {
  function getImageUrl(id: string, resolution = EImageResolution.MEDIUM) {
    return `${BASE_PATH}${apiRoutes.GET_IMAGE.replace('{id}', id).replace('{res}', resolution)}`;
  }

  return {
    getImageUrl,
  };
}
