import { AxiosInstance } from 'axios';
import { apiRoutes } from './routes';
import { IGetSearchRequest } from '@/api/request.types';
import {
  IGetDistrictsResponse,
  IGetEventCategoriesResponse,
  IGetEventsSearchResponse,
  IGetFringeEventsResponse,
  IGetOrganizersResponse,
  IGetTargetGroupsResponse,
  IGetVenuesResponse,
} from '@/api/response.types';
import { uuid } from '@/typings/custom.types';

export class Repository {
  private $axios: AxiosInstance;

  constructor(axiosInstance: AxiosInstance) {
    this.$axios = axiosInstance;
  }

  public GET_EVENTS = (payload: IGetSearchRequest): Promise<IGetEventsSearchResponse> =>
    this.$axios.get(apiRoutes.EVENT_SEARCH, { params: payload });

  public GET_EVENT_CATEGORIES = (langIso = 'de-DE'): Promise<IGetEventCategoriesResponse> =>
    this.$axios.get(apiRoutes.GET_EVENT_CATEGORIES, { headers: { 'Accept-Language': langIso } });

  public GET_VENUES = (districtCityIds?: uuid[]): Promise<IGetVenuesResponse> =>
    this.$axios.get(apiRoutes.GET_VENUES, { params: { districtIds: districtCityIds } });

  public GET_DISTRICTS = (postalCode?: string): Promise<IGetDistrictsResponse> =>
    this.$axios.get(apiRoutes.GET_DISTRICTS, { params: { postalCode } });

  public GET_ORGANIZERS = (): Promise<IGetOrganizersResponse> => this.$axios.get(apiRoutes.GET_ORGANIZERS);

  public GET_TARGET_GROUPS = (langIso = 'de-DE'): Promise<IGetTargetGroupsResponse> =>
    this.$axios.get(apiRoutes.GET_TARGET_GROUPS, { headers: { 'Accept-Language': langIso } });

  public GET_FRINGE_EVENTS = (): Promise<IGetFringeEventsResponse> => this.$axios.get(apiRoutes.GET_FRINGE_EVENTS);
}
