<template>
  <div class="rbi-toggle">
    <button
      :class="{
        'rbi-toggle__button--active': viewMode === EViewMode.GRID,
      }"
      class="rbi-toggle__button"
      type="button"
      @click="setViewMode(EViewMode.GRID)"
    >
      <svg-icon class="w-6 h-6" name="view_grid" />
    </button>
    <button
      :class="{
        'rbi-toggle__button--active': viewMode === EViewMode.LIST,
      }"
      class="rbi-toggle__button"
      type="button"
      @click="setViewMode(EViewMode.LIST)"
    >
      <svg-icon class="w-6 h-6" name="view_list" />
    </button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { EViewMode } from '@/typings/event.types';
import SvgIcon from '@/components/atoms/SvgIcon/SvgIcon.vue';

export default defineComponent({
  components: {
    SvgIcon,
  },

  props: {
    viewMode: {
      type: String as () => EViewMode,
      required: true,
    },
  },

  emits: ['set-view-mode'],

  setup(_, { emit }) {
    function setViewMode(newVal: EViewMode) {
      emit('set-view-mode', newVal);
    }

    return {
      setViewMode,
      EViewMode,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '../../../assets/styles/variables';

.rbi-toggle {
  $root: &;
  flex-direction: row;
  justify-content: flex-end;
  display: none;
  margin-top: 3px;
  margin-left: 1rem;

  @media (min-width: $screen-lg) {
    display: flex;
  }

  &__button {
    background: transparent;
    border: 0;
    color: $gray-500;
    // overwrite general button rules by parent website
    padding: 1px 6px;

    &--active {
      color: $baseColor;
    }

    svg {
      width: 1.5rem;
      height: 1.5rem;
    }

    + #{$root}__button {
      margin-left: 0.75rem;
    }
  }
}
</style>
