<template>
  <svg class="icon" xmlns="http://www.w3.org/2000/svg">
    <use :xlink:href="`#${name}`" xmlns:xlink="http://www.w3.org/1999/xlink" />
  </svg>
</template>

<script lang="ts">
import { defineComponent, onMounted } from 'vue';

export default defineComponent({
  name: 'SvgComponent',

  props: {
    name: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    onMounted(() => {
      // eslint-disable-next-line global-require,import/no-dynamic-require
      require(`@/assets/icons/${props.name}.svg`);
    });
  },
});
</script>
