<template>
  <ComponentSelector @app-config="appConfig = $event" />
  <EmbeddedContent :config="appConfig" />
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import ComponentSelector from '@/components/atoms/ComponentSelector/ComponentSelector.vue';
import EmbeddedContent from '@/components/organisms/EmbeddedContent/EmbeddedContent.vue';
import { IDefaultConfig } from '@/config/default';

export default defineComponent({
  components: { ComponentSelector, EmbeddedContent },
  setup() {
    const appConfig = ref<IDefaultConfig>({});

    return { appConfig };
  },
});
</script>
