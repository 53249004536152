import { createI18n } from 'vue-i18n';
// @ts-ignore
import langDe from '@/locales/de.json';

export const i18n = createI18n({
  locale: 'de',
  messages: {
    de: langDe,
  },
});
