<template>
  <div class="componentSelector">
    <div class="componentSelector__selector">
      <label for="cmpSel">Select Ticket:</label>
      <select id="cmpSel" v-model="selectedTicket" name="cmpSel">
        <option v-for="(ticket, index) in selectOptions" :key="index" :value="ticket">{{ ticket.label }}</option>
      </select>

      <span :title="JSON.stringify(appConfig)">(i)</span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue';
import { defaultConfig, IDefaultConfig } from '@/config/default';
import selectOptions from '@/config/configOptions';

export default defineComponent({
  name: 'ComponentSelector',

  emits: {
    appConfig: (payload: IDefaultConfig) => !!payload,
  },

  setup(_, { emit }) {
    const appConfig = ref<IDefaultConfig>({});
    const selectedTicket = ref(selectOptions[0]);

    watch(
      () => selectedTicket.value,
      (newVal) => {
        if (newVal.config) {
          appConfig.value = { ...defaultConfig, ...newVal.config };
          emit('appConfig', appConfig.value);
        }
      },
      {
        immediate: true,
      },
    );

    return {
      selectedTicket,
      selectOptions,
      appConfig,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '../../../assets/styles/variables';

.componentSelector {
  &__selector {
    height: 40px;
    border-bottom: 1px solid $gray-500;
    background: white;
    line-height: 34px;
    font-family: sans-serif;

    * + * {
      margin-left: 20px;
    }

    + * {
      margin-top: 3rem;
    }
  }
}
</style>
