import { computed, defineComponent } from 'vue';
import SvgIcon from '@/components/atoms/SvgIcon/SvgIcon.vue';
import { useHelpers } from '@/composition/helpers';

export default defineComponent({
  name: 'Pagination',

  components: {
    SvgIcon,
  },

  props: {
    totalItems: {
      type: Number as () => number,
      default: 0,
    },
    totalPages: {
      type: Number as () => number,
      default: 0,
    },
    currentPage: {
      type: Number as () => number,
      default: 0,
    },
    paginationBarMaxSize: {
      type: Number as () => number,
      default: 9, // it looks good on mobile
    },
  },

  setup(props) {
    const { getNumberSequence } = useHelpers();
    const prevPage = computed<number>(() => (props.currentPage > 0 ? props.currentPage - 1 : 0));
    const nextPage = computed<number>(() =>
      props.currentPage < props.totalPages ? props.currentPage + 1 : props.totalPages,
    );

    const shownPages = computed<number[]>(() => {
      const current = props.currentPage + 1;
      const tot = props.totalPages;
      const max = Math.min(props.paginationBarMaxSize, tot);
      const halfMax = Math.floor(max / 2);

      if (current <= halfMax) {
        return getNumberSequence(1, max);
      }

      if (current > tot - halfMax) {
        return getNumberSequence(tot - max + 1, tot);
      }

      const evenMaxPagesCorrection = max % 2 ? 0 : 1;
      return getNumberSequence(current - halfMax, current + halfMax - evenMaxPagesCorrection);
    });

    return {
      prevPage,
      nextPage,
      shownPages,
    };
  },
});
