
import { computed, defineComponent, onMounted, reactive, ref, watch } from 'vue';
import InputField from '@/components/atoms/InputField/InputField.vue';
import SelectField from '@/components/atoms/SelectField/SelectField.vue';
import { useApi } from '@/api';
import { ISimpleVenue } from '@/typings/venue.types';
import { IEventMetaInfo } from '@/typings/event.types';
import { EUserFilters, IDefaultConfig } from '@/config/default';
import { ISearchParams } from '@/typings/search.types';
import { uuid } from '@/typings/custom.types';

function useFilters() {
  const { api } = useApi();
  const venues = ref<ISimpleVenue[]>([]);
  const eventCategories = ref<IEventMetaInfo[]>([]);

  async function getVenues(districtCityIds: uuid[] = []) {
    try {
      const response = await api.GET_VENUES(districtCityIds);
      if (response?.data) {
        venues.value = response.data;
      }
    } catch (e) {
      console.error(e);
    }
  }

  async function getEventCategories() {
    try {
      const response = await api.GET_EVENT_CATEGORIES();
      if (response?.data) {
        eventCategories.value = response.data;
      }
    } catch (e) {
      console.error(e);
    }
  }

  return {
    getVenues,
    getEventCategories,
    eventCategories,
    venues,
  };
}

export default defineComponent({
  components: {
    SelectField,
    InputField,
  },

  props: {
    config: {
      type: Object as () => IDefaultConfig,
      required: true,
    },
  },

  emits: ['on-search'],

  setup(props, { emit }) {
    const { getVenues, venues, eventCategories, getEventCategories } = useFilters();
    const searchData = reactive<ISearchParams>({
      date: [],
      search: '',
      venueIds: [],
      categoryIds: [],
    });

    function onSubmit() {
      emit('on-search', searchData);
    }

    onMounted(async () => {
      await getEventCategories();
      await getVenues(props.config.filterBy?.districts);
    });

    function disabledDates(time: any) {
      return time.getTime() < Date.now() - 864e5;
    }

    watch(
      () => searchData.search,
      (newVal, oldVal) => {
        if (oldVal?.length && !newVal?.length) {
          onSubmit();
        }
      },
    );

    return {
      onSubmit,
      searchData,
      disabledDates,
      eventCategories: computed(() => eventCategories.value.map((el) => ({ value: el.id, label: el.label }))),
      venues: computed(() => venues.value.map((el) => ({ value: el.id, label: el.title }))),
      EUserFilters,
    };
  },
});
