
import { defineComponent, ref, watch } from 'vue';
import { ElConfigProvider } from 'element-plus';
import de from 'element-plus/lib/locale/lang/de';
import { IDefaultConfig } from '@/config/default';
import { useApi } from '@/api';
import { EViewMode, IEventResultItem } from '@/typings/event.types';
import Container from '@/components/atoms/Layout/Container.vue';
import SearchContainer from '@/components/organisms/SearchContainer/SearchContainer.vue';
import Pagination from '@/components/molecules/Pagination/Pagination.vue';
import LinkButton from '@/components/atoms/LinkButton/LinkButton.vue';
import EventItem from '@/components/molecules/EventItem/EventItem.vue';
import ViewModeToggle from '@/components/molecules/ViewModeToggle/ViewModeToggle.vue';
import SortButton from '@/components/atoms/SortButton/SortButton.vue';
import { ESortDir } from '@/typings/sort.types';
import { ISearchParams } from '@/typings/search.types';

function usePagination() {
  const paginationData = ref({
    currentPage: 0,
    totalItems: 0,
    totalPages: 0,
  });

  return {
    paginationData,
  };
}

function useEvents() {
  const { api, getRequestPayloadFromConfig } = useApi();
  const { paginationData } = usePagination();

  const activeSortDir = ref(ESortDir.ASC);

  const events = ref<IEventResultItem[]>([]);
  const displayEventsList = ref(true);

  async function getEventData(appConfig: IDefaultConfig) {
    try {
      const requestPayload = getRequestPayloadFromConfig(
        appConfig,
        paginationData.value.currentPage,
        activeSortDir.value,
      );
      const response = await api.GET_EVENTS(requestPayload);

      if (response.data) {
        events.value = response.data.values;

        paginationData.value.currentPage = response.data.currentPage;
        paginationData.value.totalItems = response.data.totalItems;
        paginationData.value.totalPages = response.data.totalPages;
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }

  return {
    activeSortDir,
    getEventData,
    paginationData,
    displayEventsList,
    events,
  };
}

export default defineComponent({
  name: 'EmbeddedConten',

  components: {
    Container,
    SearchContainer,
    Pagination,
    LinkButton,
    EventItem,
    ViewModeToggle,
    SortButton,
    ElConfigProvider,
  },

  props: {
    config: {
      type: Object as () => IDefaultConfig,
      required: true,
    },
  },

  setup(props) {
    const { getEventData, events, displayEventsList, paginationData, activeSortDir } = useEvents();
    const viewMode = ref(EViewMode.LIST);
    const currentSearchParams = ref({});

    async function setPage(page: number) {
      paginationData.value.currentPage = page;
      await getEventData({
        ...props.config,
        ...currentSearchParams.value,
      });
      const rootEl = document.getElementById('rbiWrapper');
      if (rootEl) rootEl.scrollIntoView({ inline: 'center' });
    }

    function setViewMode(newVal: EViewMode) {
      viewMode.value = newVal;
    }

    function onSearch(searchParams: ISearchParams) {
      currentSearchParams.value = searchParams;
      setPage(0);
    }

    watch(
      () => props.config,
      (newVal) => {
        if (newVal && newVal.filterBy) {
          getEventData(newVal);
          return;
        }
        displayEventsList.value = false;
      },
      { immediate: true },
    );

    watch(
      () => activeSortDir.value,
      async () => {
        await getEventData(props.config);
      },
    );

    return {
      events,
      paginationData,
      activeSortDir,
      setPage,
      viewMode,
      EViewMode,
      setViewMode,
      ESortDir,
      onSearch,
      locale: de,
    };
  },
});
