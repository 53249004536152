
import { computed, defineComponent, PropType } from 'vue';
import 'vue-code-highlight/themes/duotone-sea.css';
import 'vue-code-highlight/themes/window.css';
import VueCodeHighlight from 'vue-code-highlight/src/CodeHighlight.vue';
// @ts-ignore
import dedent from 'dedent';
import { ConfigKey } from '@/config/default';
// @ts-ignore
import EventsDemoHtml from '@/demos/events-demo.html';
// @ts-ignore
import ButtonDemoHtml from '@/demos/button-demo.html';

export default defineComponent({
  props: {
    header: {
      type: String,
      required: true,
    },
    fileName: {
      type: String,
      required: true,
    },
    configOptions: {
      type: Array as PropType<ConfigKey[]>,
      required: true,
    },
  },
  components: { VueCodeHighlight },
  setup(props) {
    const configOptionSnippets = computed(() => {
      return props.configOptions.reduce((prev, curr) => {
        if (curr === 'itemsPerPage') {
          return `${prev}
            // Anzahl der Events pro Seite
            itemsPerPage: 10,
          `;
        }
        if (curr === 'userFilters') {
          return `${prev}
            // Wenn 'null' oder leer wird keine Suchmaske angezeigt
            userFilters: ['date', 'categoryIds', 'venueIds'],
          `;
        }
        if (curr === 'filterBy') {
          return `${prev}
            // Vordefinierte Filter für die Event-Auflistung. Bitte die IDs eingeben.
            // Wenn filterBy auf 'null', werden keine Events gelistet
            // Sofern eine oder mehrere “categoryId”s in “filterBy” angegeben wird darf
            // “categoryId” nicht unter UserFilter gesetzt werden. Das gilt auch für "venueIds".
            filterBy: {
              districts: [],
              venueIds: [],
              organizerIds: [],
              targetGroupIds: [],
              categoryIds: [],
              frameEventIds: [],
              // eventVisibility sollte einen Wert von 'public', 'intern' oder 'all' haben.
              // Wenn nicht eingegeben, 'public' ist automatisch ausgewählt.
              eventVisibility: 'public' | 'intern' | 'all',
            },
          `;
        }
        if (curr === 'searchResultsPresets') {
          return `${prev}
            // Für die vordefinierten Suchparameter auf der Such-Ergebnisseite von region-bayreuth.de
            // Wenn 'null', verlinkt der Button auf die https://region-bayreuth.de Homepage
            // Bitte die IDs eingeben
            searchResultsPresets: {
              districts: [],
              targetGroupIds: [],
              categoryIds: [],
              frameEventIds: [],
            },
          `;
        }
        return '';
      }, '');
    });

    const htmlExample = computed(() => {
      if (props.fileName === 'rbiEvents') {
        return EventsDemoHtml;
      }
      if (props.fileName === 'rbiEventsButton') {
        return ButtonDemoHtml;
      }
      return '';
    });

    return { configOptionSnippets, htmlExample, dedent };
  },
});
