<template>
  <form v-if="config" action="" class="rbi-search glass" @submit.prevent="onSubmit">
    <input-field
      v-if="Object.keys(config.userFilters).length"
      v-model="searchData.search"
      name="searchText"
      placeholder="Was?"
      type="text"
    />
    <input-field
      v-if="config.userFilters.includes(EUserFilters.DATE)"
      v-model="searchData.date"
      :disabled-date="disabledDates"
      name="dateSelect"
      start-placeholder="Wann?"
      type="daterange"
    />
    <select-field
      v-if="config.userFilters.includes(EUserFilters.VENUE)"
      v-model="searchData.venueIds"
      :options="venues"
      collapse-tags
      filterable
      name="venueSelect"
      placeholder="Wo?"
    />
    <select-field
      v-if="config.userFilters.includes(EUserFilters.CATEGORY)"
      v-model="searchData.categoryIds"
      :options="eventCategories"
      collapse-tags
      multiple
      name="eventCategorySelect"
      placeholder="Worüber?"
    />
    <button class="rbi-btn" type="submit">Suchen</button>
  </form>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, reactive, ref, watch } from 'vue';
import InputField from '@/components/atoms/InputField/InputField.vue';
import SelectField from '@/components/atoms/SelectField/SelectField.vue';
import { useApi } from '@/api';
import { ISimpleVenue } from '@/typings/venue.types';
import { IEventMetaInfo } from '@/typings/event.types';
import { EUserFilters, IDefaultConfig } from '@/config/default';
import { ISearchParams } from '@/typings/search.types';
import { uuid } from '@/typings/custom.types';

function useFilters() {
  const { api } = useApi();
  const venues = ref<ISimpleVenue[]>([]);
  const eventCategories = ref<IEventMetaInfo[]>([]);

  async function getVenues(districtCityIds: uuid[] = []) {
    try {
      const response = await api.GET_VENUES(districtCityIds);
      if (response?.data) {
        venues.value = response.data;
      }
    } catch (e) {
      console.error(e);
    }
  }

  async function getEventCategories() {
    try {
      const response = await api.GET_EVENT_CATEGORIES();
      if (response?.data) {
        eventCategories.value = response.data;
      }
    } catch (e) {
      console.error(e);
    }
  }

  return {
    getVenues,
    getEventCategories,
    eventCategories,
    venues,
  };
}

export default defineComponent({
  components: {
    SelectField,
    InputField,
  },

  props: {
    config: {
      type: Object as () => IDefaultConfig,
      required: true,
    },
  },

  emits: ['on-search'],

  setup(props, { emit }) {
    const { getVenues, venues, eventCategories, getEventCategories } = useFilters();
    const searchData = reactive<ISearchParams>({
      date: [],
      search: '',
      venueIds: [],
      categoryIds: [],
    });

    function onSubmit() {
      emit('on-search', searchData);
    }

    onMounted(async () => {
      await getEventCategories();
      await getVenues(props.config.filterBy?.districts);
    });

    function disabledDates(time: any) {
      return time.getTime() < Date.now() - 864e5;
    }

    watch(
      () => searchData.search,
      (newVal, oldVal) => {
        if (oldVal?.length && !newVal?.length) {
          onSubmit();
        }
      },
    );

    return {
      onSubmit,
      searchData,
      disabledDates,
      eventCategories: computed(() => eventCategories.value.map((el) => ({ value: el.id, label: el.label }))),
      venues: computed(() => venues.value.map((el) => ({ value: el.id, label: el.title }))),
      EUserFilters,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '../../../assets/styles/_variables.scss';

.rbi-search {
  box-shadow: $boxShadowXLarge;
  padding: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  * {
    width: 100%;

    &:last-child {
      margin-right: 0;
    }

    @media (min-width: $screen-md) {
      margin-right: 1rem;
      width: calc(50% - 1rem);
    }

    @media (min-width: $screen-lg) {
      width: calc((100% / 3) - 1rem);
    }

    @media (min-width: $screen-xl) {
      width: calc((100% / 4) - 1rem);
    }
  }

  * + * {
    margin-top: 1rem;

    @media (min-width: $screen-md) {
      &:nth-child(2) {
        margin-top: 0;
      }
    }

    @media (min-width: $screen-lg) {
      &:nth-child(3) {
        margin-top: 0;
      }
    }
    @media (min-width: $screen-xl) {
      &:nth-child(4) {
        margin-top: 0;
      }
    }
  }
}

.rbi-btn {
  font-size: 12px;
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: auto;
}
</style>

<style lang="scss">
@import '../../../assets/styles/_variables.scss';

.rbi-popper {
  font-family: $mainFonts;
}
</style>
