import { uuid } from '@/typings/custom.types';

// eslint-disable-next-line no-shadow
export enum EUserFilters {
  DATE = 'date',
  CATEGORY = 'categoryIds',
  VENUE = 'venueIds',
}

// eslint-disable-next-line no-shadow
export enum EEventVisibility {
  PUBLIC = 'public',
  INTERN = 'intern',
  ALL = 'all',
}

interface IEventsFilter {
  districts?: uuid[];
  eventVisibility?: EEventVisibility;
  venueIds?: uuid[];
  organizerIds?: uuid[];
  targetGroupIds?: uuid[];
  frameEventIds?: uuid[];
}

interface ISearchResultsPresets {
  districts?: uuid[];
  targetGroupIds?: uuid[];
  categoryIds?: uuid[];
  frameEventIds?: uuid[];
}

export interface IDefaultConfig {
  el?: string;
  itemsPerPage?: number;
  userFilters?: EUserFilters[] | null;
  filterBy?: IEventsFilter | null;
  searchResultsPresets?: ISearchResultsPresets | null;
}

export const defaultConfig = {
  itemsPerPage: 10,
  // if empty no searchbar/filters are shown
  userFilters: null,
  // if empty no events will be listed
  filterBy: null,
  // for the link-button at the bottom
  // if empty it links to the homepage
  searchResultsPresets: null,
};

export type ConfigKey = keyof IDefaultConfig;
