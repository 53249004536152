<template>
  <div class="rbi-sort">
    <div class="rbi-sort__labelWrap">
      <div class="rbi-sort__label">{{ t('general.sortBy') }}</div>
    </div>

    <button :class="['rbi-sort__button']" type="button" @click="toggleSortDir">
      <span>
        {{ t('general.date') }}
      </span>
      <svg-icon
        :class="{ 'rbi-sort__icon--flip': modelValue === ESortDir.ASC }"
        class="rbi-sort__icon"
        name="chevron-down"
      />
    </button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import { ESortDir } from '@/typings/sort.types';
import SvgIcon from '@/components/atoms/SvgIcon/SvgIcon.vue';

export default defineComponent({
  name: 'SortButton',

  components: {
    SvgIcon,
  },

  model: {
    prop: 'sortDir',
    event: 'change',
  },

  props: {
    modelValue: {
      type: String as () => ESortDir,
      required: true,
    },
  },

  emits: ['update:modelValue'],

  setup(props, { emit }) {
    const { t } = useI18n();

    function toggleSortDir() {
      emit('update:modelValue', props.modelValue === ESortDir.ASC ? ESortDir.DESC : ESortDir.ASC);
    }

    return { ESortDir, toggleSortDir, t };
  },
});
</script>

<style lang="scss" scoped>
@import '../../../assets/styles/variables';
@import '../../../assets/styles/_mixins/form';

.rbi-sort {
  display: flex;
  flex-direction: row;
  align-items: center;

  &__label {
    @include formLabel;

    &Wrap {
      padding-top: 4px;
    }
  }

  &__button {
    display: inline-flex;
    flex-direction: row;
    font-size: 1rem;
    margin-left: 1rem;
    // overwrite general button rules by parent website
    padding: 1px 6px;
    padding-top: 2px;
    align-items: center;
    font-weight: 700;
    color: $baseColor;
    font-family: $mainFonts;
    border: 0;
    background: transparent;
  }

  &__icon {
    width: 12px;
    height: 8px;
    margin-left: 8px;
    transition: transform 0.15s ease-in-out;

    &--flip {
      transform: rotate(180deg);
    }
  }
}
</style>
