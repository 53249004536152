import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-7598a037")
const _hoisted_1 = { class: "rbi-link__wrapper rbi-wrapper" }
const _hoisted_2 = ["href"]
_popScopeId()

import qs from 'qs';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useApi } from '@/api';
import { IDefaultConfig } from '@/config/default';
import SvgIcon from '@/components/atoms/SvgIcon/SvgIcon.vue';


export default _defineComponent({
  props: {
  config: {
    type: Object as () => IDefaultConfig,
    required: true,
  },
},
  setup(__props) {

const props = __props
const { t } = useI18n();



function makeUrlPath(config: IDefaultConfig) {
  const basePath = '/suche?type=all&sort=eventDateTimes.date%3Aasc';

  if (!config?.searchResultsPresets) {
    return basePath;
  }

  const { categoryIds, districts, frameEventIds, targetGroupIds } = config.searchResultsPresets;

  const presetsParams = qs.stringify(
    {
      categoryIds,
      districts,
      frameEventIds,
      targetGroupIds,
    },
    { indices: false },
  );

  return `${basePath}&${presetsParams}`.replace(/&$/, '');
}

const url = computed(() => process.env.VUE_APP_BASE_URL + makeUrlPath(props.config));

const buttonText = computed(() => {
  if (props.config?.searchResultsPresets) {
    return t('general.searchResultLink');
  }
  return t('general.generalLink');
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("a", {
      href: _unref(url),
      class: "rbi-btn rbi-btn--icon",
      rel: "noopener nofollow",
      target: "_blank"
    }, [
      _createVNode(SvgIcon, { name: "logo-invert" }),
      _createElementVNode("span", null, _toDisplayString(_unref(buttonText)), 1)
    ], 8, _hoisted_2)
  ]))
}
}

})