<template>
  <article
    :class="[
      'rbi-event',
      {
        'rbi-event--list': viewMode === EViewMode.LIST,
        'rbi-event--grid': viewMode === EViewMode.GRID,
      },
    ]"
    data-test="event-list-item"
    itemscope
    itemtype="https://schema.org/Event"
  >
    <a :href="eventLink" class="rbi-event__wrapper" itemprop="url" rel="noopener nofollow" target="_blank">
      <div class="rbi-event__image">
        <figure
          v-if="eventData.image"
          :content="getImageUrl(eventData.image.id, EImageResolution.MEDIUM)"
          class="rbi-event__figure"
          itemprop="image"
        >
          <div v-if="eventData.image" class="rbi-event__img-wrap">
            <img
              :alt="eventData.image.title"
              :src="getImageUrl(eventData.image.id, EImageResolution.MEDIUM)"
              class="rbi-event__img"
              decoding="async"
              height="252"
              loading="lazy"
              width="386"
            />
          </div>

          <div class="rbi-event__date rbi-event__date--alternative rbi-date glass">
            <div class="rbi-date__month">{{ date.month }}</div>
            <div class="rbi-date__day">{{ date.day }}</div>
            <div class="rbi-date__time">{{ date.time }}</div>
          </div>

          <small v-if="eventData.image" class="rbi-event__copyright"> &copy; {{ eventData.image.copyright }} </small>
        </figure>
      </div>
      <div class="rbi-event__content">
        <div class="flex-grow-0">
          <div class="rbi-event__date">
            <time :content="eventStartDateTime" :datetime="eventData.dateInfo.date.start" itemprop="startDate"
              >{{ eventStartDate }}
            </time>
            <time :content="eventEndDateTime" :datetime="eventData.dateInfo.date.end" itemprop="endDate"
              >{{ eventEndDate }}
            </time>
          </div>
          <header>
            <h1 class="rbi-event__title" itemprop="name">{{ eventData.title }}</h1>
          </header>

          <div
            v-if="eventData.venue"
            class="rbi-event__location"
            itemprop="location"
            itemscope
            itemtype="https://schema.org/Place"
          >
            <span itemprop="name">{{ eventData.venue.name }}</span>
            <div
              v-if="eventData.venue.address"
              class="hidden"
              itemprop="address"
              itemscope
              itemtype="https://schema.org/PostalAddress"
            >
              <span itemprop="streetAddress"
                >{{ eventData.venue.address.street }} {{ eventData.venue.address.houseNumber }}</span
              ><br />
              <span itemprop="postalCode">{{ eventData.venue.address.postalCode }}</span
              ><span itemprop="addressLocality">{{ eventData.venue.address.city }}</span>
            </div>
          </div>

          <div
            v-else-if="eventData.districtCity"
            class="rbi-event__location"
            itemprop="address"
            itemscope
            itemtype="https://schema.org/PostalAddress"
          >
            <span itemprop="postalCode">{{ eventData.districtCity.district }}</span
            >, <span itemprop="addressLocality">{{ eventData.districtCity.city }}</span>
          </div>

          <div class="rbi-event__description" itemprop="description">
            <p>{{ shortDesc }}</p>
          </div>
        </div>
        <div class="rbi-event__price-wrap">
          <div
            v-if="eventData.price && eventData.price.type !== EEventPriceType.FREE"
            class="rbi-event__price"
            itemprop="offers"
            itemscope
            itemtype="https://schema.org/Offer"
          >
            {{ eventData.price.info.from !== eventData.price.info.to ? 'ab' : '' }}
            <span :content="eventData.price.info.from" itemprop="price">{{
              formatCurrency(eventData.price.info.from)
            }}</span>
            <meta content="EUR" itemprop="priceCurrency" />
          </div>
        </div>
      </div>
    </a>
  </article>
</template>

<script lang="ts" src="./EventItemScript.ts" />

<style lang="scss" scoped src="./EventItem.scss" />
