<template>
  <h2>Ids</h2>
  <p>
    Aus den folgenden Tabellen können die relevanten Ids für die Configs entnommen werden. Klicken Sie dazu auf den
    Titel, um die jeweilige Tabelle zu öffnen:
  </p>
  <br />
  <el-collapse>
    <el-collapse-item v-if="districts.length > 0" title="District Ids">
      <el-table :data="districts" :default-sort="{ prop: 'value', order: 'ascending' }">
        <el-table-column prop="value" label="District" />
        <el-table-column prop="id" label="Id" />
      </el-table>
    </el-collapse-item>

    <el-collapse-item v-if="venues.length > 0" title="Venue Ids">
      <el-table :data="venues" :default-sort="{ prop: 'title', order: 'ascending' }">
        <el-table-column prop="title" label="Venue" />
        <el-table-column prop="id" label="Id" />
      </el-table>
    </el-collapse-item>

    <el-collapse-item v-if="organizers.length > 0" title="Organizer Ids">
      <el-table :data="organizers" :default-sort="{ prop: 'name', order: 'ascending' }">
        <el-table-column prop="name" label="Organizer" />
        <el-table-column prop="id" label="Id" />
      </el-table>
    </el-collapse-item>

    <el-collapse-item v-if="targetGroups.length > 0" title="Target Group Ids">
      <el-table :data="targetGroups" :default-sort="{ prop: 'label', order: 'ascending' }">
        <el-table-column prop="label" label="Target Group" />
        <el-table-column prop="id" label="Id" />
      </el-table>
    </el-collapse-item>

    <el-collapse-item v-if="fringeEvents.length > 0" title="Frame Event Ids">
      <el-table :data="fringeEvents" :default-sort="{ prop: 'title', order: 'ascending' }">
        <el-table-column prop="title" label="Frame Event" />
        <el-table-column prop="id" label="Id" />
      </el-table>
    </el-collapse-item>

    <el-collapse-item v-if="categories.length > 0" title="Category Ids">
      <el-table row-key="id" :data="categories" :default-sort="{ prop: 'label', order: 'ascending' }">
        <el-table-column prop="label" label="Category" />
        <el-table-column prop="id" label="Id" />
      </el-table>
    </el-collapse-item>
  </el-collapse>
</template>

<script lang="ts">
import { defineComponent, PropType, reactive, toRefs } from 'vue';
import { ElTable, ElTableColumn, ElCollapse, ElCollapseItem } from 'element-plus';
import { useApi } from '@/api';
import { ConfigKey } from '@/config/default';
import { IDistrict } from '@/typings/district.types';
import { IOrganizer } from '@/typings/organizer.types';
import { ISimpleVenue } from '@/typings/venue.types';
import { ITargetGroup } from '@/typings/target-group.types';
import { IEventMetaInfo, IFringeEventDetails } from '@/typings/event.types';

const { api } = useApi();

export default defineComponent({
  components: { ElTable, ElTableColumn, ElCollapse, ElCollapseItem },
  props: {
    configOptions: {
      type: Array as PropType<ConfigKey[]>,
      required: true,
    },
  },

  setup(props) {
    const data = reactive({
      districts: [] as IDistrict[],
      venues: [] as ISimpleVenue[],
      organizers: [] as IOrganizer[],
      targetGroups: [] as ITargetGroup[],
      fringeEvents: [] as IFringeEventDetails[],
      categories: [] as IEventMetaInfo[],
    });

    api
      .GET_DISTRICTS()
      .then((result) => {
        data.districts = result.data;
      })
      .catch((err) => console.log('Could not fetch districts', err));

    api
      .GET_TARGET_GROUPS()
      .then((result) => {
        data.targetGroups = result.data;
      })
      .catch((err) => console.log('Could not fetch targetGroups', err));

    api
      .GET_FRINGE_EVENTS()
      .then((result) => {
        data.fringeEvents = result.data;
      })
      .catch((err) => console.log('Could not fetch fringeEvents', err));

    api
      .GET_EVENT_CATEGORIES()
      .then((result) => {
        data.categories = result.data;
      })
      .catch((err) => console.log('Could not fetch categories', err));

    if (props.configOptions.includes('filterBy')) {
      api
        .GET_VENUES()
        .then((result) => {
          data.venues = result.data;
        })
        .catch((err) => console.log('Could not fetch venues', err));

      api
        .GET_ORGANIZERS()
        .then((result) => {
          data.organizers = result.data;
        })
        .catch((err) => console.log('Could not fetch organizers', err));
    }

    return { ...toRefs(data) };
  },
});
</script>
