import { computed, defineComponent, ref, watch } from 'vue';
import { ElDatePicker, ElSelect, ElOption } from 'element-plus';
import cloneDeep from 'lodash/cloneDeep';
import { format } from 'date-fns';
import { de } from 'date-fns/locale';
import { ISelectOption } from '@/typings/select.types';
import SvgIcon from '@/components/atoms/SvgIcon/SvgIcon.vue';

export default defineComponent({
  components: {
    SvgIcon,
    ElDatePicker,
    ElSelect,
    ElOption,
  },

  props: {
    label: {
      type: String,
      default: '',
    },
    description: {
      type: [String, Boolean],
      default: '',
    },
    name: {
      required: true,
      type: String,
    },
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    prefix: {
      type: String,
      default: '',
    },
    dateFormat: {
      type: String,
      default: null,
    },
    modelValue: {
      type: [String, Date, Array, Number],
      default: '',
    },
    maxLength: {
      type: Number,
      default: null,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    errorMessages: {
      type: Array as () => string[],
      default: () => [],
    },
    selectOptions: {
      type: Array as () => ISelectOption[],
      default: () => [],
    },
    icon: {
      type: String,
      default: '',
    },
    autocomplete: {
      type: String,
      default: '',
    },
    startPlaceholder: {
      type: String,
      default: '',
    },
    endPlaceholder: {
      type: String,
      default: '',
    },
    maxlength: {
      type: [String, Boolean],
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    valueFormat: {
      type: String,
      default: '',
    },
    disabledDate: {
      type: Function as () => unknown,
      default: () => ({}),
    },
  },

  emits: ['update:modelValue'],

  setup(props, { emit }) {
    const isFocused = ref<boolean>(false);
    const inputRef = ref<HTMLElement>();
    const transformedPlaceholder = computed(() => props.placeholder.replace('\\n', '\n'));

    function getValueFromProps(val: any) {
      if (!Array.isArray(val)) return val;
      const transformedValue = cloneDeep(val);
      if (transformedValue[0])
        transformedValue[0] = format(transformedValue[0], 'yyyy-MM-dd', {
          locale: de,
        });
      if (transformedValue[1])
        transformedValue[1] = format(transformedValue[1], 'yyyy-MM-dd', {
          locale: de,
        });
      return transformedValue;
    }

    const localValue = ref(getValueFromProps(props.modelValue));

    function onBlurAction() {
      isFocused.value = false;
    }

    function onWrapperFocus() {
      if (inputRef.value) inputRef.value.focus();
    }

    watch(
      () => localValue.value,
      (newVal: any) => {
        if (newVal === null) {
          emit('update:modelValue', []);
          return;
        }
        const transformedValue = cloneDeep(newVal);
        if (transformedValue[0]) transformedValue[0] = new Date(transformedValue[0]);
        if (transformedValue[1]) transformedValue[1] = new Date(transformedValue[1]);
        emit('update:modelValue', transformedValue);
      },
    );

    return {
      localValue,
      onBlurAction,
      onWrapperFocus,
      transformedPlaceholder,
      inputRef,
      isFocused,
    };
  },
});
