<template>
  <div class="select-field">
    <label v-if="label" :for="name" class="select-field__label">
      {{ label }}
      <template v-if="required">*</template>
    </label>

    <div :class="{ 'select-field__wrapper--icon': icon }" :data-test="name" class="select-field__wrapper relative">
      <svg-icon v-if="icon" :name="icon" class="select-field__icon absolute z-10" />

      <el-select
        :id="name"
        v-model="localValue"
        :collapse-tags="collapseTags"
        :disabled="disabled"
        :filterable="filterable"
        :loading="loading"
        :multiple="multiple"
        :placeholder="placeholder"
        :required="required"
        popper-class="rbi-popper"
      >
        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
      </el-select>
    </div>
  </div>
</template>

<script src="./SelectFieldScript.ts" />

<style lang="scss" scoped src="./_selectField.scss" />
