<template>
  <nav>
    <ul class="pagination">
      <li class="pagination__item">
        <button
          v-if="currentPage !== 0"
          class="pagination__link"
          type="button"
          tabindex="-1"
          @click="$emit('set-page', prevPage)"
        >
          <svg-icon name="chevron-down" class="transform rotate-90" />
        </button>

        <span v-else class="pagination__link pagination__link--disabled">
          <svg-icon name="chevron-down" class="transform rotate-90" />
        </span>
      </li>
      <li v-for="(page, index) in shownPages" :key="index" class="pagination__item">
        <button
          v-if="page - 1 !== currentPage"
          class="pagination__link"
          type="button"
          @click="$emit('set-page', page - 1)"
        >
          {{ page }}
        </button>

        <span v-else class="pagination__link pagination__link--active">{{ page }}</span>
      </li>
      <li class="pagination__item">
        <button
          v-if="currentPage + 1 !== totalPages"
          class="pagination__link"
          type="button"
          @click="$emit('set-page', nextPage)"
        >
          <svg-icon name="chevron-down" class="transform -rotate-90" />
        </button>

        <span v-else class="pagination__link pagination__link--disabled">
          <svg-icon name="chevron-down" class="transform -rotate-90" />
        </span>
      </li>
    </ul>
  </nav>
</template>

<script lang="ts" src="./PaginationScript.ts" />

<style lang="scss" scoped src="./_pagination.scss" />
