import axios from 'axios';
import qs from 'qs';
import { BASE_PATH } from '@/api/config';
import { Repository } from '@/api/repository';
import { EEventVisibility, IDefaultConfig } from '@/config/default';
import { ESortDir } from '@/typings/sort.types';
import { ISearchParams } from '@/typings/search.types';
import { IGetSearchRequest, EventTypes } from '@/api/request.types';
import { useHelpers } from '@/composition/helpers';

let apiRepository: Repository;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useApi() {
  const { convertDateToValueFormat } = useHelpers();

  function getApi() {
    if (!apiRepository) {
      const instance = axios.create({
        baseURL: BASE_PATH,
        timeout: 5000,
      });

      instance.defaults.paramsSerializer = (params) => {
        return qs.stringify(params, { indices: false }); // param=value1&param=value2
      };

      instance.interceptors.response.use(
        (response) => {
          return response;
        },
        // eslint-disable-next-line consistent-return
        (error) => {
          const originalRequest = error.config;
          // eslint-disable-next-line no-underscore-dangle
          if (error.response.status === 401 && !originalRequest._retry) {
            // eslint-disable-next-line no-underscore-dangle
            originalRequest._retry = true;
            return axios(originalRequest);
          }
        },
      );

      apiRepository = new Repository(instance);
    }
    return apiRepository;
  }

  function convertEventVisibilityToEventTypes(eventVisibility?: EEventVisibility): EventTypes[] {
    const mapping: { [key in EEventVisibility]: EventTypes[] } = {
      [EEventVisibility.PUBLIC]: ['PUBLIC'],
      [EEventVisibility.INTERN]: ['INTERN'],
      [EEventVisibility.ALL]: ['PUBLIC', 'INTERN'],
    };

    return mapping[eventVisibility || EEventVisibility.PUBLIC];
  }

  function getRequestPayloadFromConfig(
    config: IDefaultConfig & ISearchParams,
    page = 0,
    sortDir: ESortDir = ESortDir.ASC,
  ): IGetSearchRequest {
    let searchParams: IGetSearchRequest = {
      page,
      size: config.itemsPerPage,
      sort: [`eventDateTimes.date:${sortDir.toLowerCase()}`],
    };

    if (config.filterBy) {
      const { eventVisibility, ...rest } = config.filterBy;

      searchParams = {
        ...searchParams,
        ...rest,
        // To make things simpler for the user, in the config interface the
        // eventVisibility field is a single value of type EEventVisibility. The
        // backend however, requires an array of values (see
        // IGetSearchRequest). Hence this conversion.
        eventTypes: convertEventVisibilityToEventTypes(eventVisibility),
      };
    }

    if (config.search) {
      searchParams = {
        ...searchParams,
        search: config.search,
      };
    }

    if (config.categoryIds) {
      searchParams = {
        ...searchParams,
        categoryIds: config.categoryIds,
      };
    }

    if (config.venueIds && !config.filterBy?.venueIds) {
      searchParams = {
        ...searchParams,
        venueIds: config.venueIds,
      };
    }

    if (config.date?.length) {
      searchParams = {
        ...searchParams,
        from: convertDateToValueFormat(config.date[0]),
        to: convertDateToValueFormat(config.date[1]),
      };
    }

    return searchParams;
  }

  return { api: getApi(), convertEventVisibilityToEventTypes, getRequestPayloadFromConfig };
}
