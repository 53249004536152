import { DateValue, uuid } from '@/typings/custom.types';

export type EventTypes = 'PUBLIC' | 'INTERN' | 'SURROUND';

export interface IGetSearchRequest {
  page?: number;
  size?: number;
  search?: string;
  from?: DateValue;
  to?: DateValue;
  districts?: uuid[] | uuid;
  eventTypes?: Array<EventTypes>;
  sort?: string[];
  eventIds?: uuid[];
  organizerIds?: uuid[];
  targetGroupIds?: uuid[];
  eventFormIds?: uuid[];
  categoryIds?: uuid[];
  venueIds?: uuid[];
  frameEventIds?: uuid[];
}

// eslint-disable-next-line no-shadow
export enum EImageResolution {
  XSMALL = 'XSMALL',
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
  XLARGE = 'XLARGE',
}
