import { computed, defineComponent } from 'vue';
import { EEventPriceType, EViewMode, IEventResultItem } from '@/typings/event.types';
import { useHelpers } from '@/composition/helpers';
import { useMedia } from '@/composition/media';
import { EImageResolution } from '@/api/request.types';

export default defineComponent({
  name: 'EventItem',

  props: {
    eventData: {
      type: Object as () => IEventResultItem,
      required: true,
    },

    viewMode: {
      type: String as () => EViewMode,
      default: () => EViewMode.LIST,
    },
  },

  setup(props) {
    const { getSplittedDate, getEventStartDate, getEventEndDate, getEventDateTime, formatCurrency } = useHelpers();
    const { getImageUrl } = useMedia();

    const shortDescMaxLength = 210;

    const date = computed(() => getSplittedDate(props.eventData.dateInfo.date.start, props.eventData.dateInfo.start));

    const eventStartDate = computed(() => getEventStartDate(props.eventData.dateInfo));
    const eventStartDateTime = computed(() => getEventDateTime(props.eventData.dateInfo));
    const eventEndDate = computed(() => getEventEndDate(props.eventData.dateInfo));
    const eventEndDateTime = computed(() => getEventDateTime(props.eventData.dateInfo));

    const shortDesc = computed(() =>
      props.eventData.description && props.eventData.description.length > shortDescMaxLength
        ? `${props.eventData.description.substr(0, shortDescMaxLength)}...`
        : props.eventData.description,
    );

    const eventLink = computed(() => `${process.env.VUE_APP_BASE_URL}/veranstaltung/${props.eventData.slug}`);

    return {
      date,
      eventLink,
      EViewMode,
      shortDesc,
      eventStartDate,
      eventEndDate,
      eventStartDateTime,
      eventEndDateTime,
      getImageUrl,
      EImageResolution,
      EEventPriceType,
      formatCurrency,
    };
  },
});
