<template>
  <div class="input-field">
    <label v-if="label" :for="name" class="input-field__label">
      {{ label }}
      <template v-if="required">*</template>
    </label>

    <div
      :class="[
        'input-field__wrapper',
        {
          'input-field__wrapper--focused': isFocused,
          'input-field__wrapper--disabled': disabled,
          'input-field__wrapper--nostyle': type === 'dropzone',
        },
      ]"
      @click="onWrapperFocus"
      @focus="onWrapperFocus"
    >
      <label v-if="prefix" class="input-field__text">
        {{ prefix }}
        <input
          :id="name"
          ref="inputRef"
          :aria-disabled="disabled"
          :disabled="disabled"
          :maxlength="maxlength"
          :name="name"
          :placeholder="placeholder"
          :required="required"
          :type="type"
          :value="modelValue"
          class="input-field__input"
          @blur="onBlurAction"
          @focus="isFocused = true"
          @input="$emit('update:modelValue', $event.target.value)"
        />
      </label>

      <template v-else>
        <template v-if="type.includes('date')">
          <svg-icon class="input-field__icon input-field__icon--date" name="calendar" />

          <template v-if="type === 'dateRange'">
            <span class="inline-block px-2">-</span>
            <input
              :id="`${name}-end`"
              ref="inputRef"
              v-model="localValue[1]"
              :aria-disabled="disabled"
              :data-test="`${name}-end`"
              :disabled="disabled"
              :placeholder="placeholder"
              type="date"
            />
          </template>

          <el-date-picker
            v-else
            ref="inputRef"
            v-model="localValue"
            :disabled-date="disabledDate"
            :end-placeholder="endPlaceholder"
            :start-placeholder="startPlaceholder"
            :type="type"
            format="DD.MM.YYYY"
            popper-class="rbi-popper"
            prefix-icon="hidden"
            @blur="onBlurAction"
            @focus="isFocused = true"
          />
        </template>

        <template v-else-if="type === 'tags-input'">
          <el-select
            :id="name"
            :aria-disabled="disabled"
            :data-test="name"
            :disabled="disabled"
            :placeholder="transformedPlaceholder"
            :popper-append-to-body="false"
            :value="modelValue"
            allow-create
            class="input-field--tags"
            default-first-option
            filterable
            multiple
            popper-class="rbi-popper"
            @blur="onBlurAction"
            @focus="isFocused = true"
            @input="$emit('update:modelValue', $event)"
          />
        </template>

        <template v-else>
          <svg-icon v-if="icon" :name="icon" class="input-field__icon text-gray-500 block" />

          <input
            :id="name"
            ref="inputRef"
            :aria-disabled="disabled"
            :autocomplete="autocomplete"
            :class="{
              'input-field__input--hasIcon': icon,
            }"
            :disabled="disabled"
            :maxlength="maxlength"
            :name="name"
            :placeholder="placeholder"
            :required="required"
            :type="type"
            :value="modelValue"
            class="input-field__input"
            @blur="onBlurAction"
            @focus="isFocused = true"
            @input="$emit('update:modelValue', $event.target.value)"
          />

          <button
            v-if="clearable && !disabled && type !== 'search'"
            v-show="modelValue && modelValue.length > 0"
            class="input-field__reset"
            data-test="inputReset"
            tabindex="9999"
            type="button"
            @click.prevent="$emit('update:modelValue', '')"
          >
            <svg-icon name="clear" />
          </button>
        </template>
      </template>
    </div>
    <div v-if="description" class="input-field__desc">
      {{ description }}
    </div>
    <template v-if="errorMessages">
      <div v-for="(message, index) in errorMessages" :key="index" class="input-field__error" data-test="input-error">
        {{ $t(message) }}
      </div>
    </template>
  </div>
</template>

<script lang="ts" src="./InputFieldScript.ts" />

<style lang="scss" scoped src="./_inputField.scss" />
<style lang="scss" src="./_inputCalendar.scss" />
