
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import { ESortDir } from '@/typings/sort.types';
import SvgIcon from '@/components/atoms/SvgIcon/SvgIcon.vue';

export default defineComponent({
  name: 'SortButton',

  components: {
    SvgIcon,
  },

  model: {
    prop: 'sortDir',
    event: 'change',
  },

  props: {
    modelValue: {
      type: String as () => ESortDir,
      required: true,
    },
  },

  emits: ['update:modelValue'],

  setup(props, { emit }) {
    const { t } = useI18n();

    function toggleSortDir() {
      emit('update:modelValue', props.modelValue === ESortDir.ASC ? ESortDir.DESC : ESortDir.ASC);
    }

    return { ESortDir, toggleSortDir, t };
  },
});
