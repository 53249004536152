import { format } from 'date-fns';
import { de } from 'date-fns/locale';
import { DateValue } from '@/typings/custom.types';
import { IEventDateInfoExtend, IEventDateTime } from '@/typings/event.types';

export function useHelpers() {
  function convertDateToValueFormat(date: Date | string): DateValue {
    const d = new Date(date);
    let month = `${d.getMonth() + 1}`;
    let day = `${d.getDate()}`;
    const year = d.getFullYear();

    if (month.length < 2) month = `0${month}`;
    if (day.length < 2) day = `0${day}`;

    return [year, month, day].join('-');
  }

  function getSplittedDate(date: Date | string | boolean, time: string) {
    const realDate = new Date(String(date));
    return {
      month: format(realDate, 'LLL', { locale: de }),
      day: format(realDate, 'dd', { locale: de }),
      // time: format(time, 'kk:mm')
      time: time.substr(0, 5),
    };
  }

  function getEventDateTime(dateInfo: IEventDateInfoExtend) {
    return `${dateInfo.date.start}T${dateInfo.start}Z`;
  }

  function getEventStartDate(dateInfo: IEventDateInfoExtend) {
    const baseDate = new Date(String(dateInfo.date.start));
    let dateFormat = 'EEEEEE., dd. MMMM';

    if (dateInfo.date.end && dateInfo.date.end === dateInfo.date.start) {
      const baseEndDate = new Date(String(dateInfo.date.end));
      if (
        baseEndDate.getFullYear() !== baseDate.getFullYear() ||
        baseDate.toLocaleDateString() === baseEndDate.toLocaleDateString()
      ) {
        dateFormat += ' yyyy';
      }
    }

    return `${format(baseDate, dateFormat, { locale: de })}, ${dateInfo.start.substr(0, 5)}`;
  }

  function getEventEndDateTime(dates: IEventDateTime[]) {
    return `${dates[dates.length - 1].date}T${dates[dates.length - 1].end}Z`;
  }

  function getEventEndDate(dateInfo: IEventDateInfoExtend) {
    const baseDate = new Date(String(dateInfo.date.start));
    let date = '';

    if (dateInfo.date.end && dateInfo.date.end !== dateInfo.date.start) {
      const baseEndDate = new Date(String(dateInfo.date.end));
      const time = dateInfo.end.substr(0, 5);

      if (baseDate.toLocaleDateString() !== baseEndDate.toLocaleDateString()) {
        date += ` – ${baseEndDate.toLocaleDateString('de-DE', {
          weekday: 'short',
          day: 'numeric',
          month: 'long',
          year: 'numeric',
        })}, ${time}`;
      } else {
        date += ` - ${time}`;
      }
    }

    return `${date} Uhr`;
  }

  function formatCurrency(amount: number) {
    return Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 2,
    }).format(amount);
  }

  function getNumberSequence(from: number, to: number) {
    return [...Array(to - from + 1)].map((_, index) => index + from);
  }

  return {
    getEventEndDateTime,
    formatCurrency,
    getEventEndDate,
    convertDateToValueFormat,
    getSplittedDate,
    getEventStartDate,
    getEventDateTime,
    getNumberSequence,
  };
}
