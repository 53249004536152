<template>
  <h1>{{ header }}</h1>

  <h2>Vorbreitung</h2>
  <p>
    <!-- Zip is only available on production, because it is generated on build -->
    Als erstes müssen Sie die Library als Zip <a :href="`/${fileName}.zip`">hier</a> runterladen und entpacken. Laden
    Sie dann die gewünschte Datei <code>{{ fileName }}.umd.min.js</code> sowie <code>{{ fileName }}.css</code> auf Ihren
    Webserver. Zudem, platzieren Sie den “fonts” Ordner im selben Ordner, wie die <code>css</code> Datei.
  </p>

  <h2>Einbindung der Suchmaske</h2>
  <p>
    Zur Einbindung der Event-Auflistung verwenden Sie folgenden Code an der Stelle in Ihrem Markup, an der die Events
    erscheinen sollen:
  </p>
  <vue-code-highlight>
    {{ dedent(`
    <div id="rbiEvents">
      <rbi-events :config="{}"></rbi-events>
    </div>
    `) }}
  </vue-code-highlight>
  <p>Im <code>&lt;head&gt;&lt;/head&gt;</code> wird die CSS Datei ingebunden:</p>
  <vue-code-highlight> {{ `<link rel="stylesheet" href="${fileName}.css" />` }} </vue-code-highlight>
  <p>und am Ende Ihrer Seite vor dem <code>&lt;/body&gt;</code>-Tag:</p>
  <vue-code-highlight>
    {{ dedent(`
    <script src="https://unpkg.com/vue@3.2.6/dist/vue.global.prod.js"></script>
    <script src="./${fileName}.umd.min.js"></script>

    `) }}
  </vue-code-highlight>
  <p>
    Den Pfad der Datei <code>./{{ fileName }}.umd.min.js</code> müssen Sie entsprechend anpassen (siehe Vorbereitung).
  </p>
  <p>Auf einer sehr simplen Seite sieht das ganze HTML dann z.B. so aus:</p>
  <vue-code-highlight>
    {{ htmlExample }}
  </vue-code-highlight>

  <h2>Konfiguration</h2>
  <p>Ihnen steht folgende Kofiguration zur Verfügung:</p>
  <vue-code-highlight>
    {{ dedent(`
    <div id="rbiEvents">
      <rbi-events
        :config="{
            ${configOptionSnippets}
          }"
      ></rbi-events>
    </div>
    `) }}
  </vue-code-highlight>
  <p>
    HINWEIS: Bei den Klammern <code>[]</code> handelt es sich um Listen. Also es können mehrere Werte eingegeben werden.
    Zum Beispiel zwei Ids
    <code>['a75d709f-c3ee-47c9-9c30-6728cd91f127', 'df010e41-1b89-4c8e-b7d4-26f06fd98fd9']</code>
  </p>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';
import 'vue-code-highlight/themes/duotone-sea.css';
import 'vue-code-highlight/themes/window.css';
import VueCodeHighlight from 'vue-code-highlight/src/CodeHighlight.vue';
// @ts-ignore
import dedent from 'dedent';
import { ConfigKey } from '@/config/default';
// @ts-ignore
import EventsDemoHtml from '@/demos/events-demo.html';
// @ts-ignore
import ButtonDemoHtml from '@/demos/button-demo.html';

export default defineComponent({
  props: {
    header: {
      type: String,
      required: true,
    },
    fileName: {
      type: String,
      required: true,
    },
    configOptions: {
      type: Array as PropType<ConfigKey[]>,
      required: true,
    },
  },
  components: { VueCodeHighlight },
  setup(props) {
    const configOptionSnippets = computed(() => {
      return props.configOptions.reduce((prev, curr) => {
        if (curr === 'itemsPerPage') {
          return `${prev}
            // Anzahl der Events pro Seite
            itemsPerPage: 10,
          `;
        }
        if (curr === 'userFilters') {
          return `${prev}
            // Wenn 'null' oder leer wird keine Suchmaske angezeigt
            userFilters: ['date', 'categoryIds', 'venueIds'],
          `;
        }
        if (curr === 'filterBy') {
          return `${prev}
            // Vordefinierte Filter für die Event-Auflistung. Bitte die IDs eingeben.
            // Wenn filterBy auf 'null', werden keine Events gelistet
            // Sofern eine oder mehrere “categoryId”s in “filterBy” angegeben wird darf
            // “categoryId” nicht unter UserFilter gesetzt werden. Das gilt auch für "venueIds".
            filterBy: {
              districts: [],
              venueIds: [],
              organizerIds: [],
              targetGroupIds: [],
              categoryIds: [],
              frameEventIds: [],
              // eventVisibility sollte einen Wert von 'public', 'intern' oder 'all' haben.
              // Wenn nicht eingegeben, 'public' ist automatisch ausgewählt.
              eventVisibility: 'public' | 'intern' | 'all',
            },
          `;
        }
        if (curr === 'searchResultsPresets') {
          return `${prev}
            // Für die vordefinierten Suchparameter auf der Such-Ergebnisseite von region-bayreuth.de
            // Wenn 'null', verlinkt der Button auf die https://region-bayreuth.de Homepage
            // Bitte die IDs eingeben
            searchResultsPresets: {
              districts: [],
              targetGroupIds: [],
              categoryIds: [],
              frameEventIds: [],
            },
          `;
        }
        return '';
      }, '');
    });

    const htmlExample = computed(() => {
      if (props.fileName === 'rbiEvents') {
        return EventsDemoHtml;
      }
      if (props.fileName === 'rbiEventsButton') {
        return ButtonDemoHtml;
      }
      return '';
    });

    return { configOptionSnippets, htmlExample, dedent };
  },
});
</script>
