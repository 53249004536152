import { createApp } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import { routes } from '@/router/routes';
import { i18n } from '@/plugins/i18n';
import './assets/styles/element-ui.scss';
import './assets/styles/rbi-events.scss';
import App from '@/App.vue';

const router = createRouter({
  history: createWebHistory(),
  routes,
});

createApp(App).use(i18n).use(router).mount('#rbiEvents');
