
import { defineComponent } from 'vue';
import { EViewMode } from '@/typings/event.types';
import SvgIcon from '@/components/atoms/SvgIcon/SvgIcon.vue';

export default defineComponent({
  components: {
    SvgIcon,
  },

  props: {
    viewMode: {
      type: String as () => EViewMode,
      required: true,
    },
  },

  emits: ['set-view-mode'],

  setup(_, { emit }) {
    function setViewMode(newVal: EViewMode) {
      emit('set-view-mode', newVal);
    }

    return {
      setViewMode,
      EViewMode,
    };
  },
});
