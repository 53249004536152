<template>
  <container>
    <el-main>
      <template v-if="isValidProp">
        <setup-docs :file-name="fileName" :header="header" :config-options="configOptions" />
        <id-tables :config-options="configOptions" />
      </template>
      <p v-else>Die Dokumentation für den URL-Pfad /{{ doctype }} wurde nicht gefunden. Bitte prüfen Sie die URL.</p>
    </el-main>
  </container>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';
import { ElMain } from 'element-plus';
import Container from '@/components/atoms/Layout/Container.vue';
import SetupDocs from '@/components/organisms/Docs/SetupDocs.vue';
import IdTables from '@/components/organisms/Docs/IdTables.vue';
import { ConfigKey } from '@/config/default';

export default defineComponent({
  props: {
    doctype: String as PropType<'events' | 'button'>,
  },
  components: { Container, ElMain, SetupDocs, IdTables },
  setup(props) {
    const isValidProp = computed(() => props.doctype === 'button' || props.doctype === 'events');

    const fileName = computed(() => (props.doctype === 'events' ? 'rbiEvents' : 'rbiEventsButton'));
    const header = computed(() => (props.doctype === 'events' ? 'RBI Events Skript' : 'RBI Button Skript'));
    const configOptions = computed<ConfigKey[]>(() =>
      props.doctype === 'events'
        ? ['itemsPerPage', 'userFilters', 'filterBy', 'searchResultsPresets']
        : ['itemsPerPage', 'searchResultsPresets'],
    );

    return { isValidProp, fileName, header, configOptions };
  },
});
</script>

<style lang="scss">
// Overwrite full size width of highlight component
.language-javascript {
  width: auto !important;
}

body {
  counter-reset: h2;
}

h2 {
  counter-reset: h3;
}

h3 {
  counter-reset: h4;
}

h4 {
  counter-reset: h5;
}

h5 {
  counter-reset: h6;
}

h2:before {
  content: counter(h2, decimal) '. ';
  counter-increment: h2;
}

h3:before {
  content: counter(h2, decimal) '.' counter(h3, decimal) '. ';
  counter-increment: h3;
}

h4:before {
  content: counter(h2, decimal) '.' counter(h3, decimal) '.' counter(h4, decimal) '. ';
  counter-increment: h4;
}

h5:before {
  content: counter(h2, decimal) '.' counter(h3, decimal) '.' counter(h4, decimal) '.' counter(h5, decimal) '. ';
  counter-increment: h5;
}

h6:before {
  content: counter(h2, decimal) '.' counter(h3, decimal) '.' counter(h4, decimal) '.' counter(h5, decimal) '.'
    counter(h6, decimal) '. ';
  counter-increment: h6;
}

h2.nocount:before,
h3.nocount:before,
h4.nocount:before,
h5.nocount:before,
h6.nocount:before {
  content: '';
  counter-increment: none;
}
</style>
