import { EEventVisibility, EUserFilters } from '@/config/default';

export default [
  {
    value: 'frame-event-test',
    label: 'Frame Event Test',
    config: {
      userFilters: [EUserFilters.DATE, EUserFilters.CATEGORY, EUserFilters.VENUE],
      filterBy: {
        frameEventIds: ['000184bc-315b-48d1-8457-fc9370198460'],
      },
      searchResultsPresets: {
        frameEventIds: ['000184bc-315b-48d1-8457-fc9370198460'],
      },
    },
  },
  {
    value: 'rbi-718',
    label: 'RBI-718 - Kommune (z.B. Ahorntal)',
    config: {
      userFilters: [EUserFilters.DATE, EUserFilters.CATEGORY, EUserFilters.VENUE],
      filterBy: {
        districts: ['df010e41-1b89-4c8e-b7d4-26f06fd98fd9'],
      },
      searchResultsPresets: null,
    },
  },
  {
    value: 'rbi-720',
    label: 'RBI-720 - Gebiete (z.B. Neubürg)',
    config: {
      userFilters: [EUserFilters.DATE, EUserFilters.CATEGORY, EUserFilters.VENUE],
      filterBy: {
        districts: [
          'a75d709f-c3ee-47c9-9c30-6728cd91f127',
          '8c22f35d-7d3c-491c-a8a1-89f1e9525389',
          '16aa4228-5716-42a3-9274-25522a88d3b3',
          '91b058bd-6a8c-49fb-bd9d-b760a220a69a',
          '454c7e38-b061-4917-8b89-8ab0ca0e5283',
          '5bc74021-91a6-4c98-85e7-643ee80412fb',
          '2957dd22-2a00-4c21-b9f5-3b25d0098d80',
          'a0df32aa-3c37-41e4-9a08-3109d8c42dce',
          '2cb0e458-1b16-4799-8886-44bb30449844',
          '500e3548-86bb-4b8c-ad3a-5adf42cb8caf',
        ],
      },
    },
  },
  {
    value: 'rbi-719',
    label: 'RBI-719 - Kategorie (z.B. Gesundheitsregion)',
    config: {
      filterBy: {
        categoryIds: ['d7a18b94-4f8c-4f39-b8e0-3494bdada8fd'],
      },
      searchResultsPresets: {
        categoryIds: ['d7a18b94-4f8c-4f39-b8e0-3494bdada8fd'],
      },
    },
  },
  {
    value: 'rbi-721',
    label: 'RBI-721 - Veranstaltungsstätte (z.B. Das Zentrum)\n',
    config: {
      userFilters: [EUserFilters.DATE, EUserFilters.CATEGORY],
      filterBy: {
        venueIds: ['e7cac5bf-eefe-4b23-af39-d06aba69e0bd'],
      },
    },
  },
  {
    value: 'rbi-722',
    label: 'RBI-722 - Veranstalter (z.B. Jazzforum)',
    config: {
      userFilters: [EUserFilters.DATE, EUserFilters.CATEGORY, EUserFilters.VENUE],
      filterBy: {
        organizerIds: ['42f97e2d-d872-40f3-b862-f63d240be562'],
      },
    },
  },
  {
    value: 'rbi-723',
    label: 'RBI-723 - Zielgruppen und Kommunen (z.B. Familien in Bayreuth)',
    config: {
      searchResultsPresets: {
        districts: [
          '908d5a67-bed7-4658-ae58-6ae6a7f2f776',
          'f058da00-304c-4586-b907-b1f73fde8677',
          'd67fe93d-15fe-4fe5-a2eb-d65541cc5479',
          'c084fa11-5586-4add-ae7b-aeab6c03c78e',
        ],
        targetGroupIds: ['99a15b8a-b4a0-4481-886b-1565126be83f'],
      },
    },
  },
  {
    value: 'rbi-724',
    label: 'RBI-724 - Zielgruppen (z.B. Gründer*innen)',
    config: {
      userFilters: [EUserFilters.DATE, EUserFilters.VENUE],
      filterBy: {
        targetGroupIds: ['607d17bb-9f5b-4c09-b5cb-2cf3df22df90'],
      },
    },
  },
  {
    value: 'rbi-1128',
    label: 'RBI-1128 - Skript Rahmenveranstaltungen',
    config: {
      itemsPerPage: 10,
      userFilters: [EUserFilters.DATE, EUserFilters.CATEGORY, EUserFilters.VENUE],
      filterBy: {
        districts: [
          '908d5a67-bed7-4658-ae58-6ae6a7f2f776',
          'f058da00-304c-4586-b907-b1f73fde8677',
          'd67fe93d-15fe-4fe5-a2eb-d65541cc5479',
          'c084fa11-5586-4add-ae7b-aeab6c03c78e',
        ],
        venueIds: [],
        organizerIds: [],
        targetGroupIds: [],
        frameEventIds: [],
      },
      searchResultsPresets: {
        districts: [
          '908d5a67-bed7-4658-ae58-6ae6a7f2f776',
          'f058da00-304c-4586-b907-b1f73fde8677',
          'd67fe93d-15fe-4fe5-a2eb-d65541cc5479',
          'c084fa11-5586-4add-ae7b-aeab6c03c78e',
        ],
        targetGroupIds: [],
        categoryIds: [],
        frameEventIds: [],
      },
    },
  },
  {
    value: 'rbi-1188',
    label: 'RBI-1188 - Event types',
    config: {
      userFilters: [EUserFilters.DATE, EUserFilters.CATEGORY, EUserFilters.VENUE],
      filterBy: {
        eventVisibility: EEventVisibility.ALL,
      },
    },
  },
];
