
import { defineComponent, ref, watch } from 'vue';
import { defaultConfig, IDefaultConfig } from '@/config/default';
import selectOptions from '@/config/configOptions';

export default defineComponent({
  name: 'ComponentSelector',

  emits: {
    appConfig: (payload: IDefaultConfig) => !!payload,
  },

  setup(_, { emit }) {
    const appConfig = ref<IDefaultConfig>({});
    const selectedTicket = ref(selectOptions[0]);

    watch(
      () => selectedTicket.value,
      (newVal) => {
        if (newVal.config) {
          appConfig.value = { ...defaultConfig, ...newVal.config };
          emit('appConfig', appConfig.value);
        }
      },
      {
        immediate: true,
      },
    );

    return {
      selectedTicket,
      selectOptions,
      appConfig,
    };
  },
});
