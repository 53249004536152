import { RouteRecordRaw } from 'vue-router';
import Home from '@/views/Home.vue';
import Docs from '@/views/Docs.vue';

export const routes: RouteRecordRaw[] = [
  { path: '/', component: Home },
  {
    path: '/docs/:doctype',
    component: Docs,
    props: true,
  },
];
